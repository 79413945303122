* {
  margin: 0;
  padding: 0;
  color: white;
  border: 0;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

html, body, #root {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}
