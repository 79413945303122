.App {
  background-color: #121225;
  width: 100vw;
  min-height: 200vh;
}

#down {
  background-color: #20203f;
  position: absolute;
  bottom: 10vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 7.5vh;
  height: 7.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

#down>img {
  width: 6.5vh;
  height: 6.5vh;
  margin-top: 3px;
}

#down:hover {
  bottom: 8vh !important;
  padding: 1vh;
  background-color: #2b2b4d;
}

#down.scrolling {
  bottom: 0vh;
  background-color: #20203f00 !important;
}

#down.scrolling>img {
  opacity: 0;
}

.header-spacer {
  width: 100vw;
  height: 100px;
}

.main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.section {
  width: 100vw;
  min-height: calc(100vh - 100px);
  border-bottom: 1px #0b0b1b solid;
  /*padding-top: 100px;*/
}

.section {
  padding-top: 75px;
}

.section:last-child {
  padding-bottom: 50px;
}

#presentation {
  display: flex;
  justify-content: center;
  align-items: center;
}

#presentation>p {
  width: 75vw;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
}

#techno {
  min-height: 35vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5vh;
}

.techno-title {
  margin: 2vw;
  margin-bottom: 2vw;
  font-size: 3vw;
}

.technos {
  min-height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.technos>img {
  width: 5vw;
  padding: 2vw;
  margin: 0.5vw;
  border-radius: 15px;
  background-color: #20203f;
  border: #0b0b1b 1px solid;
  transition: all 0.25s ease-in-out;
}

.technos>img:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 50%;
    padding-top: 20px;
    border-bottom: 3px solid white;
}

.technos>img:hover {
  background-color: #2b2b4d;
  transform: scale(1.1);
}

#projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects-title {
  margin: 2vw;
  font-size: 3vw;
}

.projects-grid {
  min-height: 100vh;
  width: 75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.25em;
}

@media (max-width: 1000px) {
  .technos>img, .contact-link {
    flex: 0 0 10%;
  }
}

@media (max-width: 650px) {
  .techno-title, .projects-title, .contact-title {
    font-size: 6vw;
  }

  #presentation>p {
    font-size: 1em;
  }

  footer {
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .technos>img, .contact-link {
    flex: 0 0 15%;
  }
}