
.header {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(180deg, #08081b 0%, #121225 100%);
    transition: 0.2s;
    border-color: #08081b00;
}

.header.scrolling {
    height: 50px;
    background: linear-gradient(180deg, #08081b 50%, #121225 100%);
    border-bottom: #08081b05 1px solid;
    font-size: 12px;
}

.title {
    height: 100px;
    width: 50vw;
    padding-left: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Raleway', sans-serif;
}

.nav {
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.nav-item {
    margin-right: 25px;
    padding: 2.5px;
    cursor: pointer;
    font-size: 1.5em;
    font-family: 'Raleway', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.nav-item {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    height: 1.60em;
}

a.nav-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5px;
    width: calc(100% - 7.5px);
    height: 0.1em;
    background-color: white;
    transition: all 300ms ease-in-out;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: center;
}

a.nav-item:hover::after {
    opacity: 1;
    transform: scaleX(1);
}

#contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px;
    margin-left: -5px;
}

.contacts-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4vh;
  height: 4vh;
  aspect-ratio: 1/1;
  margin-right: -2px;
  padding: 1.25vh;
  border-left: 2px transparent solid;
  border-right: 2px transparent solid;
  transition: all 0.3s ease-in-out;
}

.contacts-link>img {
  width: 100%;
  height: 100%;
}

@media (max-height: 700px) {
    
    .contacts-link {
        width: 6vh;
        height: 6vh;
    }

}

.contacts-link:hover {
  border-left: 2px white solid;
  border-right: 2px white solid;
}

.header.scrolling .contacts-link {
    width: 2.5vh;
    height: 2.5vh;
    padding: 1vh;
}

.burger-nav {
    display: none;
    z-index: 1000;
}

.menu {
    display: none;
}

.burger-item {
    font-family: 'Raleway', sans-serif;
}

@media (max-width: 1000px) {

    .nav {
        display: none;
    }

    .title {
        width: 75vw;
        font-size: 28px;
    }

    .burger-nav {
        width: 25vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .burger-menu {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10000;
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        padding: 25px;
        cursor: pointer;
    }

    .scrolling .burger-menu {
        width: 50px;
        height: 50px;
        padding: 5px;
    }
    
    .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 1000;
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        width: 96vw;
        padding: 2vw;
        padding-top: 100px;
        background: linear-gradient(-90deg, #08081b 35%, #12122600 100%);
        transition: opacity 0.4s ease-in-out;
        opacity: 0;
    }
    
    .menu.open {
        opacity: 1;
    }
    
    .burger-item {
        position: relative;
        margin: 1vw;
        padding: 1vw;
        cursor: pointer;
        font-size: 2em;
        opacity: 0;
        transition-property: opacity;
        transition-delay: 250ms;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;
    }

    a.burger-item::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1vw;
        width: calc(100% - 2vw);
        height: 0.1em;
        background-color: white;
        transition: all 300ms ease-in-out;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: center;
    }

    a.burger-item:hover::after {
        opacity: 1;
        transform: scaleX(1);
    }

    .burger-item.disabled {
        cursor: default;
        pointer-events: none;
        user-select: none;
    }
    
    .menu.open .burger-item {
        opacity: 1;
    }

    #burger-contacts {
        display: flex;
        flex-direction: row;
    }

    .burger-contacts-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4vh;
        height: 4vh;
        aspect-ratio: 1/1;
        margin-right: -2px;
        padding: 1.25vh;
        border-left: 2px transparent solid;
        border-right: 2px transparent solid;
        transition: all 0.3s ease-in-out;
    }

    .burger-contacts-link>img {
        width: 100%;
        height: 100%;
    }

    @media (max-height: 700px) {        
        .burger-contacts-link {
            width: 6vh;
            height: 6vh;
        }

    }

    .burger-contacts-link:hover {
        border-left: 2px white solid;
        border-right: 2px white solid;
    }
    
}